import React from "react";
import { IIcon } from "./icons.types";

const Callout: React.FC<IIcon> = ({
  width = 21,
  height = 24,
  color = "currentColor"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9133 1.53468C14.368 2.78664 10.8089 5.87617 10.1646 6.50616C10.314 6.42675 13.0733 4.94716 13.6026 5.70688C14.1485 6.49054 13.9753 7.73128 12.9736 8.13752C11.972 8.54375 8.12352 7.90599 8.12352 7.90599C8.12352 7.90599 8.18196 7.7549 8.29395 7.49713L8.01134 7.4466C8.01134 7.4466 7.53849 7.10859 7.01588 6.5045C7.01617 6.50286 7.01319 6.50064 7.01319 6.50064C6.10452 5.44361 5.04957 3.5811 6.08099 1.2809C6.25845 1.05579 6.65925 1.76652 7.10586 2.78622C7.65467 4.03947 8.27328 5.7567 8.62443 6.76778C8.68322 6.64311 8.7485 6.51048 8.81698 6.37132C8.81698 6.37132 8.8195 6.3667 8.82488 6.35549C9.9075 4.18476 12.1456 0.556878 14.8303 1.03691C15.0106 1.10565 15.0225 1.28317 14.9133 1.53468Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M11.1754 9.99531C11.1754 9.99531 11.06 7.50121 13.9306 7.541C15.3367 7.56025 18.8066 8.24387 19.5704 10.347C19.9616 11.4234 19.0837 15.5868 18.7827 16.7012C17.9726 19.6993 17.8978 21.3629 16.6084 22.0195C13.8713 23.4128 10.6717 22.4744 9.93137 22.317C9.19101 22.1596 11.307 19.7265 11.307 19.7265L11.1754 9.99531Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M12.217 23.0554C10.5674 23.1008 8.94584 23.1353 7.75974 22.9986C4.87311 22.8522 2.97765 21.4869 2.63067 17.8101C2.61583 17.6493 2.60354 17.4856 2.59406 17.3156C2.56045 16.7173 2.53815 16.1036 2.52714 15.4746C2.49873 14.1076 2.5215 12.6786 2.59544 11.1877C2.75072 8.86201 4.41873 7.11057 6.81934 7.17786C7.44029 7.18277 8.05628 7.19276 8.66731 7.20784C9.73542 7.23705 10.7877 7.28641 11.824 7.35591L11.9764 7.3676C12.6352 7.41398 13.2862 7.47135 13.9294 7.53972C16.5044 7.64056 17.8842 9.58954 17.6797 11.5898C17.5684 13.9018 17.1555 16.2223 16.8235 18.4573C16.3875 21.1715 15.5653 22.7667 12.217 23.0554Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M4.85288 17.4348C4.58122 18.0601 3.65936 18.262 2.79211 17.8862C2.7372 17.8625 2.68291 17.8373 2.63058 17.8087C2.61574 17.6479 2.60344 17.4841 2.59396 17.3141C2.56036 16.7158 2.53805 16.1021 2.52705 15.4732C2.89751 15.3934 3.3427 15.4382 3.77303 15.6246C4.64049 16.0011 5.12249 16.8115 4.85288 17.4348Z"
        fill={color}
      />
      <path
        d="M6.81994 7.1769C7.44089 7.18181 8.05688 7.19181 8.66791 7.20688C8.13275 7.23624 7.5316 7.28691 6.85847 7.36967C3.01775 7.83974 2.59517 11.186 2.59517 11.186C2.75046 8.86022 4.41847 7.10878 6.81994 7.1769Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M14.3686 17.3332C15.3439 17.1376 16.0202 16.4088 15.8791 15.7056C15.738 15.0023 14.833 14.5908 13.8577 14.7864C12.8824 14.982 12.2062 15.7107 12.3472 16.414C12.4883 17.1173 13.3933 17.5288 14.3686 17.3332Z"
        fill={color}
      />
      <path
        d="M3.75999 16.2216C2.85647 16.06 2.04398 15.5954 1.49792 14.9202C0.980237 14.2791 0.74113 13.4976 0.825236 12.7191C1.0144 10.9713 2.76917 9.71559 4.737 9.92009C5.75641 10.027 6.68764 10.5156 7.29142 11.2629C7.80943 11.9041 8.04848 12.6859 7.96411 13.464C7.77494 15.2118 6.02017 16.4675 4.05234 16.263C3.95416 16.2527 3.85671 16.2389 3.75999 16.2216ZM4.94004 10.4667C4.85555 10.4516 4.77019 10.4395 4.68395 10.4304C2.99647 10.255 1.49418 11.3065 1.33544 12.7745C1.26602 13.4161 1.46558 14.0634 1.89711 14.5985C2.41566 15.2404 3.21981 15.661 4.10528 15.7534C5.79308 15.9288 7.29504 14.8773 7.45412 13.4093C7.52354 12.7678 7.32398 12.1204 6.89245 11.5853C6.42419 11.0062 5.72272 10.6066 4.94004 10.4667Z"
        fill="#FEE643"
      />
      <path
        d="M12.4526 16.4698C11.5258 16.304 10.6925 15.8276 10.1329 15.1351C9.60193 14.4778 9.35687 13.6766 9.44334 12.8793C9.6374 11.087 11.4367 9.79977 13.4542 10.0098C14.5 10.1187 15.4549 10.6203 16.0738 11.3869C16.6049 12.0439 16.8502 12.8437 16.7634 13.6427C16.5697 15.4347 14.7701 16.7219 12.7525 16.5122C12.6517 16.5016 12.5515 16.4874 12.4526 16.4698ZM13.6649 10.5571C13.5778 10.5415 13.4901 10.5292 13.4012 10.5197C11.6636 10.3395 10.1165 11.4221 9.95327 12.9343C9.8817 13.5954 10.0875 14.2625 10.5319 14.8127C11.066 15.4736 11.8941 15.9076 12.8056 16.0019C14.5432 16.1825 16.09 15.0995 16.2535 13.5874C16.3251 12.9263 16.1193 12.2592 15.6748 11.7093C15.1928 11.1125 14.4699 10.701 13.6649 10.5571Z"
        fill="#FEE643"
      />
      <path
        d="M7.68019 13.4076C7.62513 13.3978 7.57476 13.3704 7.53675 13.3294C7.49874 13.2884 7.47516 13.2361 7.46961 13.1804C7.46406 13.1248 7.47684 13.0689 7.50601 13.0212C7.53517 12.9735 7.57913 12.9366 7.63118 12.9162C7.66738 12.9027 8.51531 12.5832 9.76985 13.0252C9.80162 13.0364 9.83087 13.0537 9.85593 13.0763C9.881 13.0988 9.90138 13.126 9.91592 13.1564C9.93046 13.1868 9.93888 13.2197 9.94068 13.2534C9.94249 13.287 9.93765 13.3207 9.92644 13.3524C9.91524 13.3842 9.89788 13.4135 9.87537 13.4385C9.85286 13.4636 9.82563 13.484 9.79524 13.4985C9.76486 13.5131 9.7319 13.5215 9.69826 13.5233C9.66462 13.5251 9.63096 13.5202 9.59919 13.509C8.53606 13.1344 7.8228 13.3925 7.81554 13.3953C7.77234 13.4114 7.7256 13.4156 7.68019 13.4076Z"
        fill="#FEE643"
      />
      <path
        d="M3.58535 9.29538C3.81938 9.13065 4.06684 8.98588 4.32511 8.8626C4.5826 8.73624 4.84931 8.6296 5.12294 8.54361C5.40207 8.45738 5.68813 8.39545 5.97793 8.35853C6.27699 8.32532 6.57875 8.32454 6.87797 8.35623C6.92187 8.36079 6.96444 8.37396 7.00325 8.39497C7.04206 8.41599 7.07635 8.44444 7.10416 8.47871C7.13197 8.51298 7.15276 8.55239 7.16534 8.59469C7.17792 8.63699 7.18205 8.68136 7.17748 8.72526C7.17292 8.76916 7.15975 8.81172 7.13874 8.85053C7.11772 8.88934 7.08927 8.92363 7.055 8.95144C7.02073 8.97926 6.98132 9.00005 6.93902 9.01263C6.89672 9.02521 6.85235 9.02933 6.80845 9.02477C6.79566 9.02333 6.78297 9.02106 6.77046 9.01798L6.76031 9.01616C6.5148 8.9601 6.26407 8.93 6.01226 8.92634C5.75194 8.92811 5.49224 8.95212 5.236 8.99809C4.97428 9.0462 4.7165 9.11375 4.46482 9.20018C4.2126 9.28522 3.96771 9.39062 3.73256 9.51535L3.72546 9.51915C3.69614 9.53219 3.66308 9.5342 3.63239 9.52479C3.60171 9.51539 3.57545 9.49521 3.55847 9.46797C3.54148 9.44074 3.53492 9.40828 3.53998 9.37659C3.54504 9.34489 3.56138 9.31609 3.586 9.2955L3.58535 9.29538Z"
        fill="#1737AA"
      />
      <path
        d="M12.289 8.54473C12.9846 8.59477 13.667 8.76007 14.3083 9.03388C14.6256 9.1737 14.9257 9.34949 15.2028 9.55779C15.4761 9.77129 15.7156 10.0248 15.9133 10.3097C15.9319 10.3374 15.9395 10.3711 15.9344 10.4041C15.9294 10.4371 15.9121 10.467 15.886 10.4879C15.86 10.5087 15.827 10.519 15.7937 10.5167C15.7604 10.5144 15.7292 10.4997 15.7062 10.4754L15.7 10.4686C15.4928 10.236 15.2519 10.0357 14.9854 9.87443C14.7161 9.71918 14.43 9.59512 14.1326 9.50465C13.8329 9.41694 13.5264 9.35467 13.2162 9.31849C12.9119 9.2814 12.6049 9.27004 12.2987 9.28454L12.2881 9.28502C12.1912 9.2886 12.0967 9.25412 12.0249 9.18895C11.9531 9.12379 11.9096 9.03311 11.9038 8.93631C11.898 8.83951 11.9303 8.74428 11.9938 8.67097C12.0572 8.59766 12.1469 8.5521 12.2435 8.54403C12.2581 8.54393 12.2746 8.54418 12.289 8.54473Z"
        fill="#1737AA"
      />
      <path
        d="M4.46061 15.1905C3.64747 15.0451 3.11249 14.2097 3.26989 13.3294C3.42728 12.4492 4.21867 11.8506 5.03181 11.9959C5.61243 12.0998 6.0506 12.5542 6.19608 13.1261C6.2036 13.1586 6.2204 13.1883 6.24444 13.2115C6.26849 13.2347 6.29875 13.2504 6.33155 13.2567C6.35929 13.2619 6.38788 13.26 6.41473 13.2514C6.44158 13.2427 6.46585 13.2275 6.48535 13.2071C6.50486 13.1867 6.51898 13.1618 6.52645 13.1346C6.53392 13.1074 6.5345 13.0787 6.52814 13.0513C6.3522 12.3493 5.8105 11.7878 5.09102 11.6591C4.09121 11.4804 3.12279 12.2034 2.93225 13.2691C2.74172 14.3347 3.40032 15.3501 4.40012 15.5288C5.06884 15.6484 5.72498 15.3639 6.14123 14.8428C6.15893 14.8196 6.17034 14.7921 6.17434 14.7632C6.17834 14.7342 6.1748 14.7047 6.16407 14.6775C6.15334 14.6503 6.13577 14.6263 6.11307 14.6079C6.09037 14.5895 6.0633 14.5772 6.03448 14.5723C6.00377 14.5673 5.97227 14.5706 5.9433 14.582C5.91433 14.5933 5.88895 14.6123 5.86985 14.6368C5.52901 15.0575 4.99997 15.287 4.46061 15.1905Z"
        fill="#1737AA"
      />
      <path
        d="M4.44967 15.1853C5.27676 15.3332 6.07465 14.7405 6.23182 13.8615C6.38899 12.9825 5.84591 12.15 5.01882 12.0021C4.19173 11.8543 3.39383 12.4469 3.23667 13.3259C3.0795 14.2049 3.62258 15.0374 4.44967 15.1853Z"
        fill="white"
      />
      <path
        d="M5.07862 14.9431C5.63659 14.993 6.14069 14.4543 6.20454 13.7398C6.2684 13.0254 5.86784 12.4058 5.30986 12.3559C4.75189 12.3061 4.24779 12.8448 4.18394 13.5592C4.12008 14.2737 4.52064 14.8933 5.07862 14.9431Z"
        fill="#1737AA"
      />
      <path
        d="M11.1018 13.5568C11.1866 13.4172 11.2842 13.2857 11.3934 13.1642C11.4996 13.0434 11.6154 12.9314 11.7398 12.8294C11.9962 12.617 12.2949 12.4617 12.6161 12.3741C12.9448 12.2847 13.29 12.2747 13.6234 12.3446C13.7935 12.3646 13.9431 12.4332 14.1032 12.4811C14.2559 12.5422 14.3983 12.6187 14.5463 12.6888C14.6302 12.7284 14.6948 12.7997 14.7261 12.887C14.7573 12.9744 14.7526 13.0705 14.713 13.1544C14.6733 13.2382 14.602 13.3029 14.5147 13.3341C14.4274 13.3654 14.3312 13.3607 14.2474 13.321L14.2316 13.3131L14.185 13.2879C14.071 13.2263 13.9594 13.1533 13.843 13.0973C13.7241 13.0554 13.6078 12.9877 13.4849 12.9681C13.2452 12.8978 12.992 12.8867 12.7471 12.9358C12.4959 12.9863 12.2585 13.0899 12.0507 13.2398C11.8354 13.393 11.646 13.5797 11.4898 13.7928L11.479 13.8078C11.4455 13.8571 11.3938 13.8912 11.3353 13.9027C11.2767 13.9142 11.216 13.9022 11.1662 13.8693C11.1165 13.8363 11.0817 13.7851 11.0695 13.7267C11.0572 13.6683 11.0684 13.6075 11.1007 13.5573L11.1018 13.5568Z"
        fill="#1737AA"
      />
      <path
        d="M7.0134 6.50089C6.10473 5.44386 5.04978 3.58136 6.0812 1.28116C6.25866 1.05604 6.65946 1.76677 7.10607 2.78647C6.70326 2.13419 6.29846 1.6103 6.14535 1.91277C5.43928 3.31575 6.50126 5.56039 7.0134 6.50089Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M14.9132 1.53545C14.862 1.41342 14.7435 1.32802 14.5398 1.29159C11.9664 0.831473 9.00772 6.03074 8.82585 6.35611C9.90743 4.18553 12.1455 0.557645 14.8302 1.03768C15.0105 1.10642 15.0224 1.28394 14.9132 1.53545Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M6.86263 17.233C6.89013 17.3552 7.36536 17.3283 7.96774 17.2386C8.90918 17.0974 10.1607 16.8021 10.491 16.6844C10.5993 16.6453 10.5267 16.5316 10.4881 16.5112C9.79299 16.1425 8.82515 15.5903 8.46713 15.5287C8.43578 15.523 8.40366 15.5233 8.37239 15.5293C8.34781 15.5338 8.32418 15.5425 8.30257 15.555C7.88207 15.7917 6.82359 17.0601 6.86263 17.233Z"
        fill="#FEE643"
      />
      <path
        d="M7.17233 19.356C7.14422 19.5131 7.83137 21.5691 9.0279 21.6323C9.185 21.6405 9.34208 21.616 9.48923 21.5604C10.9498 21.0277 11.5116 18.9118 11.5127 18.8167L11.5132 18.8144L9.31635 18.6356C9.31635 18.6356 7.20383 19.1798 7.17233 19.356Z"
        fill="#FEE643"
      />
      <path
        d="M7.96755 17.2396C8.90899 17.0984 10.1605 16.8031 10.4908 16.6854C10.5992 16.6463 10.5265 16.5326 10.488 16.5122C9.7928 16.1435 8.82496 15.5913 8.46694 15.5297C8.43559 15.524 8.40347 15.5242 8.3722 15.5303C8.32846 15.6437 8.30426 15.7637 8.30064 15.8852C8.27422 16.2938 8.05893 16.842 7.96755 17.2396Z"
        fill="#D6C14F"
      />
      <path
        d="M9.05471 20.0969C8.98157 20.5533 8.98654 21.0831 9.02846 21.633C9.18556 21.6411 9.34264 21.6166 9.48979 21.561C10.9504 21.0283 11.5122 18.9125 11.5133 18.8174L11.5137 18.8151L10.0017 19.6806C10.0017 19.6806 9.17347 19.3439 9.05471 20.0969Z"
        fill="#D6C14F"
      />
      <path
        d="M6.55995 18.5018C6.72471 19.2569 7.21347 19.9387 7.80191 20.3636C8.26219 20.6964 8.78521 20.871 9.26228 20.7998C10.2406 20.6527 11.2971 19.8831 11.6523 18.7473C11.6927 18.6201 11.7233 18.49 11.7438 18.3581C11.956 17.0104 10.921 16.5678 10.3587 16.5957C9.79642 16.6236 8.99508 17.0738 8.09836 17.0915C6.80746 17.1176 6.26611 17.157 6.55995 18.5018Z"
        fill="#1737AA"
      />
      <path
        d="M7.80194 20.3638C8.26222 20.6965 8.78524 20.8712 9.26231 20.8C10.2406 20.6528 11.2971 19.8833 11.6523 18.7474C10.9633 18.5184 10.0254 18.4846 9.00503 19.0541C8.47116 19.3468 8.0484 19.807 7.80194 20.3638Z"
        fill="#DB6249"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="7.68933"
          y1="-1.76035"
          x2="14.1056"
          y2="9.32571"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.27" stopColor="#5F88F1" />
          <stop offset="0.44" stopColor="#5B85F1" />
          <stop offset="0.61" stopColor="#4F7BEF" />
          <stop offset="0.78" stopColor="#3B6AED" />
          <stop offset="0.88" stopColor="#2C5DEC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="15.8461"
          y1="6.44451"
          x2="13.7084"
          y2="26.2708"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.05" stopColor="#4675F3" />
          <stop offset="0.21" stopColor="#3465EE" />
          <stop offset="0.33" stopColor="#2C5DEC" />
          <stop offset="1" stopColor="#2049CB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="7.18614"
          y1="6.15084"
          x2="13.7078"
          y2="24.6535"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#5F88F1" />
          <stop offset="0.18" stopColor="#4B7AF3" />
          <stop offset="0.44" stopColor="#3268F4" />
          <stop offset="0.69" stopColor="#235EF6" />
          <stop offset="0.89" stopColor="#1E5AF6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="4.56251"
          y1="7.56577"
          x2="5.82133"
          y2="9.48635"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stopColor="#D4E3FA" />
          <stop offset="0.31" stopColor="#D4E3FA" />
          <stop offset="0.4" stopColor="#BFD3F8" />
          <stop offset="0.58" stopColor="#96B3F5" />
          <stop offset="0.75" stopColor="#789BF3" />
          <stop offset="0.9" stopColor="#668DF2" />
          <stop offset="1" stopColor="#5F88F1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="5.31364"
          y1="1.26456"
          x2="8.12178"
          y2="7.07792"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stopColor="#D4E3FA" />
          <stop offset="0.26" stopColor="#D4E3FA" />
          <stop offset="0.33" stopColor="#C7D9F9" />
          <stop offset="0.64" stopColor="#8FADF5" />
          <stop offset="0.87" stopColor="#6C92F2" />
          <stop offset="1" stopColor="#5F88F1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="2058.37"
          y1="606"
          x2="2466.1"
          y2="1647.03"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stopColor="#D4E3FA" />
          <stop offset="0.31" stopColor="#D4E3FA" />
          <stop offset="0.4" stopColor="#BFD3F8" />
          <stop offset="0.58" stopColor="#96B3F5" />
          <stop offset="0.75" stopColor="#789BF3" />
          <stop offset="0.9" stopColor="#668DF2" />
          <stop offset="1" stopColor="#5F88F1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { Callout };
